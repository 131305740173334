import { createContext, useContext, useEffect, useRef, useState } from "react";
import AppLayout from "./layouts";
import AppProvider from "./provider";
import AppRoutes from "./routes";
import "./scss/style.scss";
import "./scss/rtl.scss";
import "react-phone-input-2/lib/style.css";
import "./language";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-loading-skeleton/dist/skeleton.css";
import { google_client_id } from "./environment";
import { GoogleOAuthProvider } from "@react-oauth/google";

const AppContextInput = createContext();

export const useAppContextInput = () => useContext(AppContextInput);
function App() {
  const inputRef = useRef(null);
  const mobileInputRef = useRef(null);
  const [plan, setPlan] = useState("trial");
  const handleInputFocus = (props) => {
    setPlan(props);
    if (window.screen.width > 767) {
      inputRef.current.focus();
    } else {
      mobileInputRef.current.focus();
    }
  };

  return (
    <GoogleOAuthProvider clientId={google_client_id}>
      <AppProvider>
        <AppContextInput.Provider
          value={{
            inputRef,
            mobileInputRef,
            handleInputFocus,
            plan,
          }}
        >
          <AppLayout>
            <AppRoutes />
          </AppLayout>
        </AppContextInput.Provider>
      </AppProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
