import React, { useState } from "react";
import { Card, Radio, Button, Typography, message, Space } from "antd";
import DashboardHeader from "../dashboard/DashboardHeader";
import { connect } from "react-redux";
import { smsConfig } from "../../redux/slice/smsServicesSlice";

const { Title, Text } = Typography;

const SmsServices = (props) => {
  const { callSmsServices } = props;
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSubmit = async () => {
    if (selectedOption === null) {
      message.warning("Please select an option before submitting");
      return;
    }
    setLoading(true);
    try {
      const result = await callSmsServices({ preference: "sns" });
      if (result.type === "SmsServices/fulfilled") {
        message.success(result.payload.message);
      } else {
        message.error(result.error.message);
      }
    } catch (error) {
      message.error("Submission failed, please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="dashboard-main-section">
      <div className="container">
        <DashboardHeader />
        <div
          style={{
            minHeight: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Card
            style={{
              width: 600,
              textAlign: "center",
              padding: "20px 30px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Title level={3}>Choose an SMS Services option</Title>
            <Text type="secondary">
              Please select one of the options below:
            </Text>
            <Space
              direction="vertical"
              size="large"
              style={{
                width: "100%",
                marginTop: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Radio.Group
                onChange={handleRadioChange}
                value={selectedOption}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <Radio
                  value="sns"
                  style={{ marginBottom: "10px", fontSize: "16px" }}
                >
                  AWS SNS
                </Radio>
                <Radio value="twilio" style={{ fontSize: "16px" }}>
                  Twilio
                </Radio>
              </Radio.Group>
              <Button
                type="primary"
                size="large"
                loading={loading}
                onClick={handleSubmit}
                style={{ width: "100%" }}
              >
                Submit
              </Button>
            </Space>
          </Card>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    callSmsServices: (data) => dispatch(smsConfig(data)),
  };
};

export default connect(null, mapDispatchToProps)(SmsServices);
