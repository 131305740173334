import React from "react";
import "./users.scss";
import { Pagination, Table } from "antd";
import Timestamp from "react-timestamp";
import DashboardHeader from "../dashboard/DashboardHeader";

const UsersComponent = ({
  handlePaginationChange,
  pagination,
  loading,
  data,
}) => {
  const columns = [
    {
      title: "User Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Plan",
      dataIndex: "plan",
      key: "plan",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (text) => <Timestamp date={text} />,
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (text) => <Timestamp date={text} />,
    },
  ];

  const tableData = data?.map((item, index) => ({
    key: (index + 1).toString(),
    _id: item._id,
    email: item.email,
    currency: item.currency,
    amount: item.amount || item?.subscription?.amount,
    plan: item.plan,
    startDate: item.trial_start_date || item.subscription?.start_date,
    endDate: item.trial_end_date || item.subscription?.end_date,
  }));

  return (
    <div className="dashboard-main-section">
      <div className="container">
        <DashboardHeader />
        <div className="users-main">
          <div className="users-table">
            <Table
              columns={columns}
              dataSource={tableData}
              pagination={false}
              loading={loading}
            />
          </div>
        </div>
        {pagination.total > 25 && (
          <Pagination
            {...pagination}
            showSizeChanger={false}
            onChange={handlePaginationChange}
            className="user-pagination"
          />
        )}
      </div>
    </div>
  );
};

export default UsersComponent;
