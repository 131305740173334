import React, { useEffect, useState } from "react";
import "./admin.scss";
import { useTranslation } from "react-i18next";
import {
  DeleteFilled as MdDeleteForever,
  WarningFilled as CiWarning,
} from "@ant-design/icons";
import { Button, Input, Modal, Pagination, Table } from "antd";
import Timestamp from "react-timestamp";
import Notify from "../common/Notify/notify";
import { renderRTLClass, validEmail } from "../../utils/commonUtils";
import DashboardHeader from "../dashboard/DashboardHeader";

const UsersComponent = (props) => {
  const {
    callAdminDeleteUser,
    getSubscriptionDetails,
    callCreateNewAdmin,
    handlePaginationChange,
    pagination,
    loading,
    data,
  } = props;
  const { t } = useTranslation();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [addNewUser, setAddNewUser] = useState(false);
  const [selectedId, setSelectedId] = useState(false);
  const [validateErrors, setErrors] = useState({});
  const [createAdminError, setCreateAdminError] = useState("");
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    role: "admin",
  });
  
  useEffect(() => {
    const noScroll = confirmModalOpen || addNewUser;
    document.body.classList.toggle("no-scroll", noScroll);
    return () => document.body.classList.remove("no-scroll");
  }, [confirmModalOpen, addNewUser]);

  const handleCreateAdmin = async () => {
    const errors = {};
    if (!validEmail.test(userData.email)) {
      if (!userData.email) {
        errors.email = t("ENTER_EMAIL_RESET_PASSWORD");
      } else {
        errors.email = t("ENTER_VALID_EMAIL");
      }
    }
    if (!userData.first_name) {
      errors.first_name = t("BILLING_FIRST_NAME_REQUIRED");
    }
    if (!userData.last_name) {
      errors.last_name = t("BILLING_LAST_NAME_REQUIRED");
    }
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      const data = {
        first_name: userData.first_name,
        last_name: userData.last_name,
        email: userData.email.toLowerCase(),
        role: "admin",
      };
      const result = await callCreateNewAdmin(data);
      if (result.type === "createNewAdmin/fulfilled") {
        Notify("success", t("NOTIFY_NEW_USER_CREATED"), "");
        setAddNewUser(false);
        getSubscriptionDetails();
        setUserData({
          first_name: "",
          last_name: "",
          email: "",
          role: "admin",
        });
      } else {
        setCreateAdminError(result.error.message);
      }
    }
  };

  const handleCloseModal = () => {
    setConfirmModalOpen(false);
    setAddNewUser(false);
  };

  const handleConfirmDeleteUser = (id) => {
    setConfirmModalOpen(true);
    setSelectedId(id);
  };

  const handleDeleteUser = async () => {
    const result = await callAdminDeleteUser(selectedId);
    if (result.type === "adminDeleteUser/fulfilled") {
      Notify("success", t("NOTIFY_USER_DELETED"), "");
    }
    getSubscriptionDetails();
    setConfirmModalOpen(false);
  };

  const handleAddUserDetail = (event) => {
    const { name, value } = event.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const columns = [
    {
      title: "Admin Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "User Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Last Login",
      dataIndex: "last_login",
      key: "last_login",
      render: (text) => <Timestamp date={text} />,
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (text) => (
        <div className="actions">
          <MdDeleteForever
            className="action-svg red"
            onClick={() => handleConfirmDeleteUser(text)}
          />
        </div>
      ),
    },
  ];

  const tabled =
    Array.isArray(data) &&
    data?.map((item, index) => ({
      key: (index + 1).toString(),
      _id: item._id,
      email: item.email,
      name: item.name,
      country: item.country,
      last_login: item.last_login,
      action: item._id,
    }));

  return (
    <div className="dashboard-main-section">
      <div className="container">
        <DashboardHeader />
        <div className="users-main">
          <div className="users-table">
            <Table
              columns={columns}
              dataSource={tabled}
              pagination={false}
              loading={loading}
            />
          </div>
        </div>
        {pagination.total > 25 && (
          <Pagination
            {...pagination}
            showSizeChanger={false}
            onChange={handlePaginationChange}
            className="user-pagination"
          />
        )}
      </div>
      <Modal
        centered
        open={confirmModalOpen}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        onCancel={handleCloseModal}
        className={renderRTLClass()}
      >
        <div className="user-delete-confirm-model">
          <CiWarning size={90} color="#ff4d4f" />
          <div className="user-delete-text">
            {t("ADMIN_DELETE_CONFIRM_TEXT")}
          </div>
          <div>
            <Button
              type="primary"
              danger
              className="user-delete-button"
              onClick={handleCloseModal}
            >
              {t("ADMIN_DELETE_CANCEL")}
            </Button>
            <Button
              type="primary"
              className="user-delete-yes-button"
              onClick={() => handleDeleteUser()}
            >
              {t("ADMIN_DELETE_YES")}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        centered
        open={addNewUser}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        onCancel={handleCloseModal}
        className={renderRTLClass()}
      >
        <div className="add-uder-form">
          <div className="add-userinputs">
            <div className="add-user-title">
              <p>{t("CREATE_NEW_ADMIN")}</p>
            </div>
            <div className="add-user-df">
              <div className="input">
                <label>{t("FIRST_NAME_lABEL")}</label>
                <Input
                  name="first_name"
                  onChange={handleAddUserDetail}
                  value={userData.first_name}
                />
                {validateErrors.first_name && (
                  <p>{validateErrors.first_name}</p>
                )}
              </div>
              <div className="input">
                <label>{t("LAST_NAME_lABEL")}</label>
                <Input
                  name="last_name"
                  onChange={handleAddUserDetail}
                  value={userData.last_name}
                />
                {validateErrors.last_name && <p>{validateErrors.last_name}</p>}
              </div>
            </div>
            <div className="add-user-email">
              <div className="input">
                <label>{t("EMAIL_lABEL")}</label>
                <Input
                  type="email"
                  name="email"
                  onChange={handleAddUserDetail}
                  value={userData.email}
                />
                {validateErrors.email && <p>{validateErrors.email}</p>}
                {createAdminError && <p>{createAdminError}</p>}
              </div>
            </div>
            <div className="add-user-submit">
              <div className="add-user-button">
                <button onClick={handleCreateAdmin} className="hl_cta_wrap">
                  {t("ADD_USER_BTN")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UsersComponent;
