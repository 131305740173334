import React, { useEffect, useState, useMemo } from "react";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import UsersComponent from "./users";
import { subscriptionDetails } from "../../redux/slice/subscriptionDetailsSlice";
import { connect } from "react-redux";

const Users = ({ getSubscriptionDetails }) => {
  const navigate = useNavigate();
  const cookies = useMemo(() => new Cookies(), []);
  const role = cookies.get("role");
  const token = cookies.get("token");
  const lang = cookies.get("lang");

  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchData = async (page = 1) => {
    setLoading(true);
    try {
      const result = await getSubscriptionDetails(page);
      if (result.type === "subscriptionDetails/fulfilled") {
        setData(result.payload.data);
        setPagination({
          total: result.payload.count,
          current: page,
          pageSize: 25,
        });
      }
    } catch (error) {
      console.error("Failed to fetch subscription details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (role !== "admin") {
      const path = token ? `/${lang}/dash` : `/${lang}/`;
      navigate(path);
    } else {
      fetchData();
    }
    // eslint-disable-next-line
  }, []);

  const handlePaginationChange = (page) => {
    fetchData(page);
  };

  return (
    <UsersComponent
      handlePaginationChange={handlePaginationChange}
      pagination={pagination}
      loading={loading}
      data={data}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  getSubscriptionDetails: (...props) => dispatch(subscriptionDetails(...props)),
});

export default connect(null, mapDispatchToProps)(Users);